<template>
  <div>
    <CDataTable
        v-if="orders"
        hover
        striped
        :items="orders"
        :fields="can('bank_user') ? fieldsOrder : fieldsTenders"
        :pagination="$options.paginationProps"
        :table-filter="{lazy: false, placeholder: 'Знайти...', label: ' '}"
        index-column
        :sorter-value="{column:'created_at', asc:false}"
        :items-per-page-select="{label:'Кількість на сторінці: ', values:[5,10,20,30,50]}"
        :items-per-page="20"
        clickable-rows
        sorter
        :noItemsView="{noResults: 'За результатами пошуку нічого не знайдено', noItems: 'no items available'}"
        @row-clicked="rowClicked"
    >
      <template #created_at="data">
        <td>
          {{ timeConvertTender(data.item.created_at) }}
        </td>
      </template>
      <template #end_tender="data">
        <td>
          <template
              v-if="Number(data.item.end_tender) !== 0">{{
              timeConvertTender(data.item.end_tender)
            }}
          </template>
        </td>
      </template>
      <template #branch_a_address="data">
        <td>
          <span class="font-weight-bold">{{ data.item.merchant || data.item.branch_a }}</span>
          {{ data.item.branch_a_address }}
        </td>
      </template>
      <template #merchant>
        <td></td>
      </template>
      <template #branch_a>
        <td></td>
      </template>
      <template #id>
        <td></td>
      </template>
      <template #consumer_type="data">
        <td>
          {{ data.item.consumer_type }}
        </td>
      </template>
      <template #connect_type="data">
        <td>
          {{ data.item.connect_type }}
        </td>
      </template>
      <template #channel_purpose="data">
        <td>
          {{ data.item.channel_purpose }}
        </td>
      </template>
      <template #channel_speed="data">
        <td>
          {{ data.item.channel_speed }}
        </td>
      </template>
      <template #branch_b_address="data">
        <td>
          <template v-if="data.item.connect_type !== 'Інтернет'">
            <template
                class="font-weight-bold">{{
                data.item.branch_b
              }}
            </template>
            {{ data.item.branch_b_address }}
          </template>
        </td>
      </template>
      <template #status="data">
        <td v-if="data.item.status !== undefined">
          <CBadge class="p-2 " :color="getBadgeStatus(data.item.status)">
            {{ statusTender(data.item.status) }}
          </CBadge>
        </td>
      </template>
      <template #offer_status="data">
        <td>
          <CBadge v-if="data.item.offer_status !== null" class="p-2"
                  :color="getBadgeStatusOrder(data.item.offer_status)">
            {{ statusOrders(data.item.offer_status) }}
          </CBadge>
        </td>
      </template>
    </CDataTable>
    <template v-else>
      <h4 v-if="can('provider_user')">У вашому регіоні ще не проводяться тендери.</h4>
      <h4 v-if="can('bank_user')">Тендерів ще не має</h4>
    </template>
  </div>
</template>

<script lang="js">
import {fieldsTenders, fieldsOrder} from "~/Components/Grid/Fields";
import statusTenderAndPropositionMixin from "@/mixins/statusTenderAndPropositionMixin";
import timeConvertMixin from "@/mixins/timeConvertMixin";
import {getTendersOrOrders} from "@/api/getTendersOrders";
import {routerGoToName} from "@/utils/routerUtils";
import {can} from "@/rules/permissions";

export default {
  name: "TendersOrdersGrid.vue",
  mixins: [statusTenderAndPropositionMixin, timeConvertMixin],
  data() {
    return {
      orders: null,
      fieldsTenders,
      fieldsOrder
    }
  },
  paginationProps: {
    align: 'center',
    doubleArrows: false,
    previousButtonHtml: 'prev',
    nextButtonHtml: 'next',
  },
  created() {
    if (this.$store.state.login_info.ready) {
      this.funcShowTender();
    }
  },
  watch: {
    '$store.state.login_info.ready': function () {
      return this.funcShowTender();
    }
  },
  methods: {
    can,
    createOrder() {
      routerGoToName('TendersCreateNew');
      // this.$router.push({path: '/tenders/create'});
    },
    async getTender() {
      this.is_tender_count = true;
      this.orders = await getTendersOrOrders();

      if (this.orders.length) {
        this.$emit('tenders_count', this.orders.length);
        this.is_tender_count = false;
      }
    },
    rowClicked(item) {
      let location = {name: 'TendersPreview', params: {id: item.id.toString()}}
      // let link = this.$router.resolve({path: `/tenders/${item.id.toString()}`})
      let link = this.$router.resolve(location);
      window.open(link.href, '_blank');
    },
    funcShowTender() {
      // if (this.$store.state.login_info.email !== "") {
      //   this.$store.dispatch('GET_REGIONS');
      // }
      this.getTender();
    },
  },
}

</script>