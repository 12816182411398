<template>
  <CRow v-if="can('bank_user') || can('provider_user')">
    <CCol>
      <CCard>
        <CCardHeader>
          <CRow class="align-items-center">
            <CCol col="6" sm="4" md="6" xl class="mb-3 mb-xl-0 text-left">
              <h2>{{ can('provider_user') ? 'Тендери' : 'Заявки'}}</h2>
            </CCol>
            <CCol col="6" sm="8" md="6" class="mb-3 mb-xl-0 text-right">
              <BtnCSV v-if="orders"
                      class="ml-2 mt-1 p-1 px-2"
                      value="Вигрузити в CSV"
                      url="tenders"
              />
              <!--                          <CButton  v-if="order !== null" class="ml-2 mt-1 p-1 px-2" @click="getCSV" color="success" variant="outline">Вигрузити в CSV</CButton>-->
              <template v-if="can('create_tender')">
                <CButton
                    data-cy="btn-create-tender"
                    color="primary"
                    class="mt-1 ml-2 p-1 px-2"
                    @click="createOrder"
                >
                  Створити заявку
                </CButton>
              </template>
            </CCol>
          </CRow>
        </CCardHeader>
        <CCardBody>
          <template v-if="can('bank_user') || $store.state.regions">
            <CRow v-if="is_tender_count" class="justify-content-center">
              <CSpinner color="primary"
                        style="width:4rem;height:4rem;"/>
            </CRow>
            <tenders-orders-grid @tenders_count="(e) => orders = e"/>

          </template>
          <template v-else>
            <h4>Ви не стежите за тендерами у жодних областях. Виберіть область присутності у
              персональному кабінеті.</h4>
          </template>
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
import statusTenderAndPropositionMixin from '../../mixins/statusTenderAndPropositionMixin'
import timeConvertMixin from '@/mixins/timeConvertMixin';
import BtnCSV from "../Components/Button/BtnCSV";
import {fieldsTenders, fieldsOrder} from "~/Components/Grid/Fields";
import TendersOrdersGrid from "~/Components/Grid/TendersOrdersGrid.vue";
import {routerGoToName} from "@/utils/routerUtils";
import {can} from "@/rules/permissions";

export default {
  mixins: [statusTenderAndPropositionMixin, timeConvertMixin],
  components: {BtnCSV, TendersOrdersGrid},
  name: "Tender",
  data: () => {
    return {
      is_tender_count: false,
      orders: null,
      fieldsTenders,
      fieldsOrder
    }

  },
  created() {
    if (this.$store.state.login_info.ready) {
      this.getRegions();
    }
  },
  watch: {
    '$store.state.login_info.ready': function () {
      return this.getRegions();
    }
  },
  methods: {
    can,
    createOrder() {
      routerGoToName('TendersCreateNew');
      // this.$router.push({path: '/tenders/create'});
    },
    getRegions() {
      if (can('provider_user')) {
        this.$store.dispatch('GET_REGIONS');
      }
    }
  },
}
</script>
