import axios from 'axios';

const APITenders = () => {
    try {
        return axios.get('/api/orders').then(resp => resp.data);
    } catch (e) {
        return e.response.data;
    }
};

export const getTendersOrOrders = async () => {
    const response = await APITenders();
    let order = response;

    if (!order) return null;

    order = order.map((item) => {
        if (!item.offer_status) return item
        switch (item.offer_status) {
            case 'NoTechnicalPossibility':
                item._classes = 'table-danger';
                break;
            case 'ProposalSubmitted':
                item._classes = 'table-success';
                break;
            case 'Refusal':
                item._classes = 'table-dark';
                break;
            default:
                break;
        }
        return item
    });

    return order;
};